import NodeBasicPage from "@components/displays/node/node--seeds_page/default";
import {ExtendedHead} from "@components/hoc";
import {Layout} from "@components/layout/layout";
import {LayoutContext} from "@lib/hooks";
import {currentLanguage} from "@lib/translations-provider";
import {isLayoutBuilderPage} from "@lib/utils";
import {getMenues, setupLayoutBuilderField} from "@services/page-setup";
import {SeedsPargraphBlock} from "@type/block";
import {PageNode} from "@type/entity";
import {SlugProps} from "@type/general";
import {GetServerSidePropsContext, GetStaticPropsResult} from "next";
import {getDrupalClient} from "services/drupal";

type IndexPageProps = Omit<SlugProps, "resource" | "isFront" | "breadcrumbs"> & {
  resource: PageNode;
};

export default function IndexPage(props: IndexPageProps) {
  return (
    <LayoutContext.Provider value={{...props, breadcrumbs: [], isFront: true}}>
      <Layout>
        <ExtendedHead langcode={props.langcode} resource={props.resource} />
        <NodeBasicPage resource={props.resource} />
      </Layout>
    </LayoutContext.Provider>
  );
}

export async function getServerSideProps(
  context: GetServerSidePropsContext,
): Promise<GetStaticPropsResult<IndexPageProps>> {
  context.res.setHeader("Cache-Control", "public, s-maxage=10, stale-while-revalidate=59");

  if (process.env.DRUPAL_FRONT_PAGE) {
    currentLanguage.langcode = context.locale || "ar";
    const drupal = getDrupalClient();
    const resource = await drupal.getResourceByPath<PageNode>(`${process.env.DRUPAL_FRONT_PAGE}`, {
      locale: context.locale || "en",
      defaultLocale: context.defaultLocale || "en",
      withAuth: true,
    });
    if (resource == null) {
      throw new Error("The front page is not found");
    }
    if (resource != null && !isLayoutBuilderPage(resource)) {
      throw new Error("The front page is not a layout builder page");
    }
    await setupLayoutBuilderField(resource, context, resource.views_arguments);

    const {mainMenu, footerMenu, socialMenu, copyRightMenu} = await getMenues(resource, context);
    const contanctUsBlock = await drupal.getResource(
      "block_content--seeds_paragraph",
      "425a6beb-5ea8-4115-aff4-f88a67bce76f",
      {
        params: {
          include: "field_seeds_single_paragraph",
        },
        locale: context.locale || "ar",
        defaultLocale: context.defaultLocale || "ar",
        withAuth: true,
      },
    );

    const blocks: Record<string, SeedsPargraphBlock> = {
      contact_us: contanctUsBlock as SeedsPargraphBlock,
    };
    return {
      props: {
        resource,
        mainMenu,
        footerMenu,
        socialMenu,
        copyRightMenu,
        langcode: context.locale || "ar",
        metatagsProps: {},
        blocks,
      },
    };
  } else {
    return {
      notFound: true,
    };
  }
}
