import { LayoutBuilder } from "@components/hoc";
import {PageNode} from "@type/entity";
import {EntityDisplayProps} from "@type/general";

function NodeBasicPage({resource}: EntityDisplayProps<PageNode>) {
  return (
    <>
      <LayoutBuilder resource={resource} />
    </>
  );
}

export default NodeBasicPage;
